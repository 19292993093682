<template>
  <v-card
    height="100%"
    class="py-2"
  >
    <div>
      <v-tabs
        v-model="selectedTab"
        active-class="white"
        slider-color="accent"
        grow
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        class="mt-n1"
      >
        <v-tab
          v-for="(t, index) in actualTabs"
          :key="index"
          :disabled="t.disabled"
          @change="$emit('tabChanged', t); mutateTabInCaseFormsStore(t)"
        >
          <div>
            <div>{{ t.text }}</div>
            <div
              style="font-size:10px;"
            >
              {{ t.title }}
            </div>
          </div>
        </v-tab>
        <v-tab-item
          v-for="(t, index) in actualTabs"
          :key="index"
        >
          <CaseModelsTabs
            :company="t.company"
            :group="t.group"
            :is-company-tab="t.isCompanyTab"
            :is-group-tab="t.isGroupTab"
            :user-role="t.userRole"
            :is-single-acc="t.isSingleAcc"
            :is-lavvira-tab="t.isLavviraTab"
            :actual-tab="t"
          />
        </v-tab-item>
      </v-tabs>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'
import CaseModelsTabs from './CaseModelsTabs.vue'

export default {
  components: { CaseModelsTabs },
  mixins: [generalMixin],
  data () {
    return {
      selectedTab: Number(localStorage.getItem('activeCaseFormsTabIndex')) || 0
    }
  },
  computed: {
    ...mapState({
      caseForms: state => state.caseForms.caseForms,
      account: state => state.account.account,
      company: state => state.company.company
    }),
    actualTabs () {
      let tabs = []
      if (this.account.accountType === 'god') {
        tabs.push(
          {
            text: this.$t('common|my'),
            title: this.$t('case_models|case_models'),
            isMyTab: true,
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isGodAcc: true,
            isLavviraTab: false
          },
          {
            text: 'Lavvira',
            title: this.$t('case_models|case_models'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isGodAcc: true,
            isLavviraTab: true,
            isMyTab: false
          }
        )
      } else {
        if (!this.company) {
          tabs.push({
            text: this.$t('common|my'),
            title: this.$t('case_models|case_models'),
            isMyTab: true,
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: true,
            isLavviraTab: false
          },
          {
            text: 'Lavvira',
            title: this.$t('case_models|case_models'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: true,
            isLavviraTab: true
          })
        }
        if (this.company) {
          const userRole = this.verifyUserRole(this.company, this.account)
          //  verifyUserRole => generalMixin
          if (userRole !== 'regular') {
            tabs.push({
              text: this.$t('common|my'),
              title: this.$t('case_models|case_models'),
              userRole: userRole,
              isCompanyTab: false,
              isGroupTab: false,
              isSingleAcc: false,
              isLavviraTab: false,
              isMyTab: true
            },
            {
              text: 'Lavvira',
              title: this.$t('case_models|case_models'),
              userRole: userRole,
              isCompanyTab: false,
              isGroupTab: false,
              isSingleAcc: false,
              isLavviraTab: true
            })
            tabs.push({
              text: this.company.companyData.companyName,
              title: this.$t('case_models|case_models'),
              company: this.company,
              isCompanyTab: true,
              isGroupTab: false,
              userRole: userRole,
              isSingleAcc: false,
              isLavviraTab: false,
              disabled: this.company.subscriptionStatus === 'unpaid' || false
            })
            if (this.company.groups && this.company.groups.length) {
              for (let i = 0; i < this.company.groups.length; i++) {
                const group = this.company.groups[i]
                const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
                if (accountParticipation !== -1) {
                  tabs.push({
                    text: group.groupName,
                    title: this.$t('case_models|case_models'),
                    group: group,
                    isCompanyTab: false,
                    isGroupTab: true,
                    userRole: userRole,
                    isSingleAcc: false,
                    isLavviraTab: false,
                    disabled: this.company.subscriptionStatus === 'unpaid' || false
                  })
                }
              }
            }
          } else if (userRole === 'regular') {
            tabs.push({
              text: 'Lavvira',
              title: this.$t('case_models|case_models'),
              isLavviraTab: true
            })
            tabs.push({
              text: this.company.companyData.companyName,
              title: this.$t('case_models|case_models'),
              company: this.company,
              isCompanyTab: true,
              isGroupTab: false,
              userRole: userRole,
              disabled: this.company.subscriptionStatus === 'unpaid' || false
            })
            if (this.company.groups && this.company.groups.length) {
              for (let i = 0; i < this.company.groups.length; i++) {
                const group = this.company.groups[i]
                const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
                if (accountParticipation !== -1) {
                  tabs.push({
                    text: group.groupName,
                    title: this.$t('case_models|case_models'),
                    group: group,
                    isCompanyTab: false,
                    isGroupTab: true,
                    userRole: userRole,
                    isLavviraTab: false,
                    disabled: this.company.subscriptionStatus === 'unpaid' || false
                  })
                }
              }
            }
          }
        }
      }
      return tabs
    }
  },
  watch: {
    selectedTab (value) {
      if (value || value === 0) {
        const index = this.selectedTab
        this.updateActualTabCaseForms(this.actualTabs[index])
      }
      if (value || value === 0) {
        localStorage.setItem('activeCaseFormsTabIndex', value)
      }
    }
  },
  mounted () {
    const index = this.selectedTab
    this.$emit('tabChanged', this.actualTabs[index])
    this.updateActualTabCaseForms(this.actualTabs[index])
  },
  methods: {
    ...mapActions({
      updateActualTabCaseForms: 'caseForms/updateActualTabCaseForms'
    }),
    mutateTabInCaseFormsStore (tab) {
      this.updateActualTabCaseForms(tab)
    }
  }
}

</script>
