<template>
  <v-toolbar
    flat
    rounded-0
    class="mt-0"
    color="lightBackground"
  >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="deep-purple accent-4"
    />
    <div
      class="d-flex row justify-start align-center px-8"
    >
      <v-text-field
        :solo-inverted="soloInverted"
        :solo="solo"
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_case_model')"
        hide-details
        dense
        rounded
        class="shrink"
        @focus="soloInverted = true; solo = false"
        @blur="soloInverted = false; solo = true"
        @input="event => this.$emit('input', event)"
      >
        <div
          slot="label"
          :style="actualLanguage === 'bg' || actualLanguage === 'fr' ? 'font-size: 13px;' : ''"
        >
          {{ $t('actions|search_case_model') }}
        </div>
      </v-text-field>
      <v-btn
        v-if="selection === 'table'"
        color="primary"
        text
        dark
        small
        class="ml-2 pl-2 pr-2"
        @click="viewIsSelected('cards')"
      >
        {{ $t('common|cards') }}
        <v-icon
          small
          class="ml-2"
        >
          mdi-cards-variant
        </v-icon>
      </v-btn>
      <v-btn
        v-if="selection === 'cards'"
        color="primary"
        text
        dark
        small
        class="ml-2 pl-2 pr-2"
        @click="viewIsSelected('table')"
      >
        {{ $t('common|table') }}
        <v-icon
          small
          class="ml-2"
        >
          mdi-table-large
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!isLavviraTab && myCaseModels && account.accountType !== 'god'"
        color="primary"
        rounded
        small
        :disabled="!activeButton(account, company, group, actualTab)"
        @click.stop="openDialog"
      >
        <v-icon
          left
        >
          mdi-briefcase-plus-outline
        </v-icon>
        {{ $t('actions|add_new_case_model') }}
      </v-btn>
      <v-btn
        v-if="isLavviraTab && account.accountType === 'god'"
        color="primary"
        rounded
        small
        class="mb-2"
        @click.stop="openDialog"
      >
        <v-icon
          left
        >
          mdi-briefcase-plus-outline
        </v-icon>
        {{ $t('actions|add_new_case_model') }}
      </v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState } from 'vuex'
import { userHasAccess } from '@/utils/utils'

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    myCaseModels: {
      type: Boolean,
      default: true
    },
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selection: localStorage.getItem('preferedViewCaseModels') || 'cards',
      soloInverted: false,
      solo: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      actualLanguage: state => state.account.actualLanguage
    })
  },
  methods: {
    openDialog () {
      EventBus.$emit('open-new-case-model-dialog', this.actualTab)
    },
    viewIsSelected (value) {
      this.selection = value
      this.$emit('selection', value)
      localStorage.setItem('preferedViewCaseModels', value)
    },
    activeButton (account, company, group, tab) {
      return userHasAccess(account, company, group, tab)
    }
  }
}
</script>
