<template>
  <div>
    <CaseModelsBar
      v-model="caseFormsSearch"
      :company="company"
      :group="group"
      :account="account"
      :is-company-tab="isCompanyTab"
      :is-group-tab="isGroupTab"
      :user-role="userRole"
      :is-single-acc="isSingleAcc"
      :is-lavvira-tab="isLavviraTab"
      :actual-tab="actualTab"
      @selection="viewIsSelected"
    />
    <v-container
      v-if="myCaseForms && myCaseForms.length"
      fluid
      :style="`height: ${vuetifyHeight - 200 + 'px'};`"
    >
      <CaseModelsTableView
        v-if="selection === 'table'"
        :filtered-case-models-by-category="filteredCaseModelsByCategory"
      />
      <v-card
        flat
        :style="`height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
      >
        <v-row
          v-if="selection === 'cards'"
          class="mt-4 ml-9"
          justify="start"
        >
          <div
            v-for="(caseForm, i) in filteredCaseModelsByCategory"
            :key="caseForm._id"
            class="mx-3 mb-3 mt-0"
          >
            <CaseModelCard
              :case-form="caseForm"
              :card-index="i"
            />
          </div>
        </v-row>
      </v-card>
    </v-container>
    <v-container
      v-else
      :style="`height: ${vuetifyHeight - 200 + 'px'};`"
    >
      <div
        class="center"
      >
        <v-icon
          color="primary"
          class="mb-1"
        >
          mdi-briefcase-edit-outline
        </v-icon>
        <span class="ml-2 mb-n1">{{ $t('case_models|status_no_case_models') }}</span>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import CaseModelsBar from './CaseModelsBar.vue'
import CaseModelCard from './CaseModelCard.vue'
import CaseModelsTableView from './CaseModelsTableView.vue'
import moment from 'moment'
const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: { CaseModelsBar, CaseModelCard, CaseModelsTableView },
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selection: localStorage.getItem('preferedViewCaseModels') || 'cards',
      caseFormsSearch: '',
      selectedByCategorie: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      lavviraCaseForms: state => state.caseForms.lavviraCaseForms
    }),
    vuetifyHeight () {
      return this.$vuetify.breakpoint.height
    },
    myCaseForms () {
      let myActualCaseForms = []
      if (this.isSingleAcc && !this.isLavviraTab) {
        if (this.caseForms && this.caseForms.length) {
          myActualCaseForms = this.caseForms
        }
      } else if (this.isSingleAcc && this.isLavviraTab) {
        myActualCaseForms = []
      }
      if (!this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && !this.isSingleAcc && this.userRole !== 'regular') {
        if (this.caseForms && this.caseForms.length) {
          myActualCaseForms = this.caseForms
        }
      }
      if (this.isCompanyTab) {
        myActualCaseForms = this.companyCaseForms
      }
      if (this.isGroupTab) {
        if (this.isGroupTab && this.group) {
          this.groupCaseForms.forEach(_caseForm => {
            const index = _caseForm.sharedWith.groups.findIndex(gr => gr._id === this.group._id)
            if (index !== -1) {
              myActualCaseForms.push(_caseForm)
            }
          })
        }
      }
      if (this.isLavviraTab) {
        myActualCaseForms = this.lavviraCaseForms
      }
      return myActualCaseForms
    },
    _caseForms () {
      return this.myCaseForms.map((c) => {
        const data = {
          ...c,
          updatedAt: createdAtFormat(c.updatedAt),
          createdAt: createdAtFormat(c.createdAt)
        }
        return data
      })
    },
    filteredCaseModelsByCategory () {
      if (this.selectedByCategorie || (this.caseFormsSearch && this.caseFormsSearch.length)) {
        return this._caseForms.filter((c) => {
          return c.category.toLowerCase().includes(this.selectedByCategorie.toLowerCase()) && c.name.toLowerCase().includes(this.caseFormsSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === '' || (this.caseFormsSearch && this.caseFormsSearch.length)) {
        return this._caseForms.filter((c) => {
          return c.name.toLowerCase().includes(this.caseFormsSearch.toLowerCase())
        })
      } else if (this.selectedByCategorie === 'none' || (this.caseFormsSearch && this.caseFormsSearch.length)) {
        return this._caseForms.filter((c) => {
          return c.name.toLowerCase().includes(this.caseFormsSearch.toLowerCase())
        })
      }
      return this._caseForms
    }
  },
  mounted () {
    this.toggleDrawer(true)
  },
  async created () {
    EventBus.$on('category-is-selected', this.onCategoryIsSelected)
  },
  beforeDestroy () {
    EventBus.$off('category-is-selected', this.onCategoryIsSelected)
  },
  methods: {
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    viewIsSelected (value) {
      this.selection = value
    },
    onCategoryIsSelected (selectedItem) {
      this.selectedByCategorie = selectedItem
    }
  }
}

</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
